import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Field from 'core/components/Form/Field';
import { FullRowCell } from 'core/components/GridCell';
import ResourceFormDropdown from 'core/components/ResourceFormDropdown';
import { getResourceUrl } from 'core/functions/getResourcesUrl';
import { useBoolClientOption } from 'core/hooks/useClientOption';
import usePermission from 'core/hooks/usePermission';
import { LabelEntity } from 'lists/models/Label';

const LabelsField: FC<{ fieldName?: string; entityName: LabelEntity }> = ({
  fieldName = 'labels',
  entityName,
}) => {
  const { t } = useTranslation();
  const labelsEnabled = useBoolClientOption('enableLabels');
  const canSeeLabels = usePermission('labels:find');

  if (!labelsEnabled || !canSeeLabels) {
    return null;
  }

  const labelsResourceUrl = getResourceUrl('labels', {
    entityName,
  });

  return (
    <FullRowCell>
      <Field
        label={t('Labels')}
        component={ResourceFormDropdown}
        name={fieldName}
        resource={labelsResourceUrl}
        fast={false}
      />
    </FullRowCell>
  );
};

export default LabelsField;
