import { useCallback } from 'react';

import { EntityLabel } from 'lists/models/Label';

import useLabelsSchema from './useLabelsSchema';

const useLabelsInputMapping = () => {
  const labelsSchema = useLabelsSchema();

  return useCallback(
    (labels: EntityLabel[]) =>
      labelsSchema.cast(
        {
          labels: labels.map((it) => it.id) || [],
        },
        { stripUnknown: true }
      ),
    [labelsSchema]
  );
};

export default useLabelsInputMapping;
