import { ConnectedRouter } from 'connected-react-router';
import { History } from 'history';
import { FC, PropsWithChildren, useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Provider, useDispatch } from 'react-redux';
import { Redirect, Switch, useLocation } from 'react-router-dom';
import { ToastContainer, Zoom, toast } from 'react-toastify';
import { Store } from 'redux';
import { Persistor } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import { entities } from 'app/entity';
import { RolesId } from 'app/models/Role';
import { logout } from 'core/actions';
import FocusWorkaround from 'core/components/FocusWorkaround';
import IconButton from 'core/components/IconButton';
import ErrorPage from 'core/containers/ErrorPage';
import { DetailModeEnum } from 'core/containers/FormDetailPage';
import FullscreenRoute from 'core/containers/FullscreenRoute';
import PermissionsPage from 'core/containers/PermissionsPage';
import SidebarRoute from 'core/containers/SidebarRoute';
import safeLazy from 'core/functions/safeLazy';
import ApiCallToasts from 'core/hooks/useApiCall/ApiCallToasts';
import useAppSelector from 'core/hooks/useAppSelector';
import 'react-toastify/dist/ReactToastify.css';

const Dashboard = safeLazy(() => import('app/pages/DashboardPage'));
const DownloadPage = safeLazy(() => import('app/pages/DownloadPage'));
const VerifyAccreditationCard = safeLazy(
  () => import('personnel/containers/VerifyAccreditationCardPage')
);
const Login = safeLazy(() => import('core/containers/LoginPage'));
const ForgotPassword = safeLazy(() => import('core/containers/ForgotPasswordPage'));
const ResetPassword = safeLazy(() => import('core/containers/ResetPasswordPage'));
const ActivateAccount = safeLazy(() => import('core/containers/ActivateAccountPage'));
const UnlockAccount = safeLazy(() => import('core/containers/UnlockAccountPage'));
const AccountPage = safeLazy(() => import('app/pages/AccountPage'));

const Athletes = safeLazy(() => import('personnel/containers/AthletesPage'));
const AthleteDetailPage = safeLazy(() => import('personnel/containers/AthleteDetailPage'));

const CoC = safeLazy(() => import('planning/containers/CoCPage'));
// @ts-ignore don't know why it is problem
const CocDetailPage = safeLazy(() => import('planning/containers/CocDetailPage'));

const Clients = safeLazy(() => import('personnel/containers/ClientsPage'));
const ClientDetailPage = safeLazy(() => import('personnel/containers/ClientDetailPage'));

const Events = safeLazy(() => import('planning/containers/EventsPage'));
const EventDetailPage = safeLazy(() => import('planning/containers/EventDetailPage'));

const Missions = safeLazy(() => import('planning/containers/MissionsPage'));
const MissionDetailPage = safeLazy(() => import('planning/containers/MissionDetailPage'));
const MissionBulkEditPage = safeLazy(() => import('planning/containers/MissionBulkEditPage'));

const Samples = safeLazy(() => import('planning/containers/SamplesPage'));
const SampleDetailPage = safeLazy(() => import('planning/containers/SampleDetailPage'));

const TdpsPage = safeLazy(() => import('planning/containers/TdpsPage'));
const TdpsDetailPage = safeLazy(() => import('planning/containers/TdpsDetailPage'));

const Tests = safeLazy(() => import('planning/containers/TestsPage'));
const TestDetailPage = safeLazy(() => import('planning/containers/TestDetailPage'));
const TestBulkEditPage = safeLazy(() => import('planning/containers/TestBulkEditPage'));
const TestIndividualBulkEditPage = safeLazy(
  () => import('planning/containers/TestIndividualBulkEditPage')
);

const TestReturnsPage = safeLazy(() => import('planning/containers/TestReturnsPage'));
const TestReturnDetailPage = safeLazy(() => import('planning/containers/TestReturnDetailPage'));

const TestResultDetailPage = safeLazy(() => import('planning/containers/TestResultDetailPage'));

const ControlReports = safeLazy(() => import('planning/containers/ControlReportsPage'));
const ControlReportDetailPage = safeLazy(
  () => import('planning/containers/ControlReportDetailPage')
);

const UAReports = safeLazy(() => import('planning/containers/UAReportsPage'));
const UAReportsDetail = safeLazy(() => import('planning/containers/UAReportsDetailPage'));

const AaReports = safeLazy(() => import('planning/containers/AaReportsPage'));
const AaReportsDetail = safeLazy(() => import('planning/containers/AaReportsDetailPage'));

const Users = safeLazy(() => import('personnel/containers/UsersPage'));
const UserEditPage = safeLazy(() => import('personnel/containers/UsersDetailPage'));

const ControlSettings = safeLazy(() => import('settings/containers/ControlSettingsPage'));
const CommunicationSettings = safeLazy(
  () => import('settings/containers/OutboundCommunicationPage')
);
const ADAMSSettings = safeLazy(() => import('settings/containers/AdamsSettingsPage'));
const ApplicationsContent = safeLazy(() => import('settings/containers/ApplicationContentsPage'));
const CurrentClient = safeLazy(() => import('settings/containers/ClientPage'));

const Authority = safeLazy(() => import('lists/containers/AuthoritiesPage'));
const AuthorityDetailPage = safeLazy(() => import('lists/containers/AuthorityDetailPage'));
const Federation = safeLazy(() => import('lists/containers/FederationsPage'));
const FederationDetailPage = safeLazy(() => import('lists/containers/FederationDetailPage'));

const FileType = safeLazy(() => import('lists/containers/FileTypesPage'));
const FileTypeDetailPage = safeLazy(() => import('lists/containers/FileTypeDetailPage'));

const DocumentType = safeLazy(() => import('lists/containers/DocumentTypesPage'));
const DocumentTypeDetailPage = safeLazy(() => import('lists/containers/DocumentTypeDetailPage'));

const AvailabilityCategory = safeLazy(() => import('lists/containers/AvailabilityCategoriesPage'));
const AvailabilityCategoryDetailPage = safeLazy(
  () => import('lists/containers/AvailabilityCategoryDetailPage')
);

const InvoicingCodes = safeLazy(() => import('lists/containers/InvoicingCodesPage'));
const InvoicingCodeDetailPage = safeLazy(() => import('lists/containers/InvoicingCodeDetailPage'));

const Language = safeLazy(() => import('lists/containers/LanguagesPage'));
const LanguageDetailPage = safeLazy(() => import('lists/containers/LanguageDetailPage'));

const Country = safeLazy(() => import('lists/containers/CountriesPage'));
const CountryDetailPage = safeLazy(() => import('lists/containers/CountryDetailPage'));

const Disability = safeLazy(() => import('lists/containers/DisabilitiesPage'));
const DisabilityDetailPage = safeLazy(() => import('lists/containers/DisabilityDetailPage'));

const Locale = safeLazy(() => import('lists/containers/LocalesPage'));
const LocaleDetailPage = safeLazy(() => import('lists/containers/LocaleDetailPage'));

const PaymentMethod = safeLazy(() => import('lists/containers/PaymentMethodsPage'));
const PaymentMethodDetailPage = safeLazy(() => import('lists/containers/PaymentMethodDetailPage'));

const Courier = safeLazy(() => import('lists/containers/CouriersPage'));
const CourierDetailPage = safeLazy(() => import('lists/containers/CourierDetailPage'));

const Label = safeLazy(() => import('lists/containers/LabelsPage'));
const LabelDetailPage = safeLazy(() => import('lists/containers/LabelDetailPage'));

const Laboratory = safeLazy(() => import('lists/containers/LaboratoriesPage'));
const LaboratoryDetailPage = safeLazy(() => import('lists/containers/LaboratoryDetailPage'));
const SampleManufacturerPage = safeLazy(() => import('lists/containers/SampleManufacturersPage'));
const SampleManufacturerDetailPage = safeLazy(
  () => import('lists/containers/SampleManufacturerDetailPage')
);
const TdpClassesPage = safeLazy(() => import('lists/containers/TdpClassesPage'));
const TdpClassesDetailPage = safeLazy(() => import('lists/containers/TdpClassesDetailPage'));

const SportDiscipline = safeLazy(() => import('lists/containers/DisciplinesPage'));
const SportDisciplineDetailPage = safeLazy(() => import('lists/containers/DisciplineDetailPage'));

const Pool = safeLazy(() => import('lists/containers/PoolsPage'));
const PoolDetailPage = safeLazy(() => import('lists/containers/PoolDetailPage'));

const Sport = safeLazy(() => import('lists/containers/SportsPage'));
const SportDetailPage = safeLazy(() => import('lists/containers/SportDetailPage'));

const LocationsPage = safeLazy(() => import('lists/containers/LocationsPage'));
const LocationsDetailPage = safeLazy(() => import('lists/containers/LocationDetailPage'));

const DcasPage = safeLazy(() => import('lists/containers/DcasPage'));
const DcasDetailPage = safeLazy(() => import('lists/containers/DcasDetailPage'));

const AnalysesPage = safeLazy(() => import('lists/containers/AnalysesPage'));
const AnalysesDetailPage = safeLazy(() => import('lists/containers/AnalysesDetailPage'));

const FilesPage = safeLazy(() => import('core/containers/FilesPage'));
const AgendaPage = safeLazy(() => import('personnel/containers/AgendaPage'));

const ReportsPage = safeLazy(() => import('planning/containers/ReportsPage'));
const ClientStatisticsPage = safeLazy(
  () => import('planning/containers/ReportsPage/ClientsStatistics')
);

const OrdersPage = safeLazy(() => import('planning/containers/OrdersPage'));
const OrdersDetailPage = safeLazy(() => import('planning/containers/OrdersDetailPage'));
const OrderItemDetailPage = safeLazy(() => import('planning/containers/OrderItemDetailPage'));
const OrderItemBulkEditPage = safeLazy(() => import('planning/containers/OrderItemBulkEditPage'));

const UAInternalReasonsPage = safeLazy(() => import('lists/containers/UAInternalReasonsPage'));
const UAInternalReasonsDetailPage = safeLazy(
  () => import('lists/containers/UAInternalReasonsDetailPage')
);

const TestCancellationReasonsPage = safeLazy(
  () => import('lists/containers/TestCancellationReasonsPage')
);
const TestCancellationReasonsDetailPage = safeLazy(
  () => import('lists/containers/TestCancellationReasonsDetailPage')
);
const TestReturnReasonsPage = safeLazy(() => import('lists/containers/TestReturnReasonsPage'));
const TestReturnReasonsDetailPage = safeLazy(
  () => import('lists/containers/TestReturnReasonsDetailPage')
);
const TestReturnResolutionsPage = safeLazy(
  () => import('lists/containers/TestReturnResolutionsPage')
);
const TestReturnResolutionsDetailPage = safeLazy(
  () => import('lists/containers/TestReturnResolutionsDetailPage')
);

const CostsPage = safeLazy(() => import('finance/containers/CostsPage'));
const CostsDetailPage = safeLazy(() => import('finance/containers/CostsDetailPage'));

const PaymentPlansPage = safeLazy(() => import('finance/containers/PaymentPlansPage'));
const PaymentPlansDetailPage = safeLazy(() => import('finance/containers/PaymentPlanDetailPage'));

const DCOInvoiceDetailPage = safeLazy(() => import('finance/containers/DCOInvoiceDetailPage'));
const DCOInvoicesPage = safeLazy(() => import('finance/containers/DCOInvoicesPage'));

const AuditLogsPage = safeLazy(() => import('settings/containers/AuditLogsPage'));
const AuditLogsDetailPage = safeLazy(() => import('settings/containers/AuditLogsDetailPage'));

const TranslationsListPage = safeLazy(() => import('translations/pages/TranslationsListPage'));
const TranslationsDetailPage = safeLazy(() => import('translations/pages/TranslationsDetailPage'));

interface Props {
  store: Store;
  history: History;
  persistor: Persistor;
}

const ID_PARAM = ':id(\\d+)';
const TEST_ID_PARAM = ':testsId(\\d+)';
const ORDER_ID_PARAM = ':ordersId(\\d+)';
const USER_ID_PARAM = ':usersId(\\d+)';

const App: FC<Props> = ({ store, history, persistor }) => {
  const { t } = useTranslation();
  const { pathname } = history.location;
  const detailShortcut = pathname.match(/^\/(\w+?)s?\/(?:edit\/)?(\d+)\/?$/);
  const detailShortcutEntity = detailShortcut?.[1] as undefined | keyof typeof entities;
  const detailShortcutId = detailShortcut?.[2];

  return (
    <Provider store={store}>
      <HelmetProvider>
        <FocusWorkaround />
        <ToastContainer
          position={toast.POSITION.BOTTOM_RIGHT}
          className="material-redesign"
          closeButton={({ closeToast }) => <IconButton onClick={closeToast} icon="close" />}
          transition={Zoom}
          hideProgressBar
          newestOnTop
          icon={false}
        />
        <PersistGate persistor={persistor}>
          <ApiCallToasts />
          <ConnectedRouter history={history}>
            <AppRedirectGuard>
              <Switch>
                <FullscreenRoute exact path="/forgot-password" component={ForgotPassword} />
                <FullscreenRoute exact path="/reset-password/:code" component={ResetPassword} />
                <FullscreenRoute exact path="/activate-account/:code" component={ActivateAccount} />
                <FullscreenRoute exact path="/unlock-account/:code" component={UnlockAccount} />
                <FullscreenRoute
                  exact
                  path="/accreditation-card/:uuid"
                  component={VerifyAccreditationCard}
                />
                <FullscreenRoute path="/login" component={Login} />
                <SidebarRoute exact path="/" permission="dashboard:view" component={Dashboard} />
                <SidebarRoute path="/download" permission="app:find" component={DownloadPage} />
                <SidebarRoute path="/account" permission="profile:find" component={AccountPage} />
                <SidebarRoute
                  exact
                  path={entities.athlete.urls().list}
                  permission="athletes:find"
                  component={Athletes}
                />
                <SidebarRoute
                  path={entities.athlete.urls().detail(ID_PARAM)}
                  permission="athletes:get"
                  component={AthleteDetailPage}
                  mode={DetailModeEnum.EDIT}
                />
                <SidebarRoute
                  path={entities.athlete.urls().create}
                  permission="athletes:create"
                  component={AthleteDetailPage}
                  mode={DetailModeEnum.CREATE}
                />
                <SidebarRoute
                  path={entities.athlete.urls().clone(ID_PARAM)}
                  permission="athletes:create"
                  component={AthleteDetailPage}
                  mode={DetailModeEnum.CLONE}
                />
                <SidebarRoute
                  clientOptions={['enableDcoReport', 'enableDcr']}
                  path={entities.testDcoReport.urls(TEST_ID_PARAM).list}
                  component={ControlReports}
                  exact
                />
                <SidebarRoute
                  clientOptions={['enableDcoReport', 'enableDcr']}
                  path={entities.testDcoReport.urls(TEST_ID_PARAM).create}
                  component={ControlReportDetailPage}
                  mode={DetailModeEnum.CREATE}
                />
                <SidebarRoute
                  clientOptions={['enableDcoReport', 'enableDcr']}
                  path={entities.testDcoReport.urls(TEST_ID_PARAM).detail(ID_PARAM)}
                  component={ControlReportDetailPage}
                  mode={DetailModeEnum.EDIT}
                />
                <SidebarRoute
                  clientOptions={['enableDcoReport', 'enableDcr']}
                  path={entities.testDcoReport.urls(TEST_ID_PARAM).clone(ID_PARAM)}
                  component={ControlReportDetailPage}
                  mode={DetailModeEnum.CLONE}
                />
                <SidebarRoute
                  permission="testUaReports:find"
                  component={UAReports}
                  path={entities.testUaReport.urls(TEST_ID_PARAM).list}
                  exact
                />
                <SidebarRoute
                  path={entities.testUaReport.urls(TEST_ID_PARAM).detail(ID_PARAM)}
                  permission="testUaReports:get"
                  component={UAReportsDetail}
                  mode={DetailModeEnum.EDIT}
                />
                <SidebarRoute
                  path={entities.testUaReport.urls(TEST_ID_PARAM).create}
                  permission="testUaReports:create"
                  component={UAReportsDetail}
                  mode={DetailModeEnum.CREATE}
                />
                <SidebarRoute
                  path={entities.testUaReport.urls(TEST_ID_PARAM).clone(ID_PARAM)}
                  permission="testUaReports:create"
                  component={UAReportsDetail}
                  mode={DetailModeEnum.CLONE}
                />
                <SidebarRoute
                  path={entities.testAaReport.urls().list}
                  permission="testAaReports:find"
                  component={AaReports}
                  exact
                  clientOptions={['enableAaReport']}
                />
                <SidebarRoute
                  path={entities.testAaReport.urls().detail(ID_PARAM)}
                  permission="testAaReports:get"
                  component={AaReportsDetail}
                  mode={DetailModeEnum.EDIT}
                  clientOptions={['enableAaReport']}
                />
                <SidebarRoute
                  path={entities.coc.urls().list}
                  permission="cocs:find"
                  component={CoC}
                  exact
                />
                <SidebarRoute
                  path={entities.coc.urls().detail(ID_PARAM)}
                  permission="cocs:get"
                  component={CocDetailPage}
                  mode={DetailModeEnum.EDIT}
                />
                <SidebarRoute
                  path={entities.coc.urls().create}
                  permission="cocs:create"
                  component={CocDetailPage}
                  mode={DetailModeEnum.CREATE}
                />
                <SidebarRoute
                  path={entities.coc.urls().clone(ID_PARAM)}
                  permission="cocs:create"
                  component={CocDetailPage}
                  mode={DetailModeEnum.CLONE}
                />
                <SidebarRoute
                  path={entities.client.urls().list}
                  exact
                  permission="clients:find"
                  component={Clients}
                />
                <SidebarRoute
                  path={entities.client.urls().detail(ID_PARAM)}
                  permission="clients:patch"
                  component={ClientDetailPage}
                  mode={DetailModeEnum.EDIT}
                />
                <SidebarRoute
                  path={entities.client.urls().create}
                  permission="clients:create"
                  component={ClientDetailPage}
                  mode={DetailModeEnum.CREATE}
                />
                <SidebarRoute
                  path={entities.client.urls().clone(ID_PARAM)}
                  permission="clients:create"
                  component={ClientDetailPage}
                  mode={DetailModeEnum.CLONE}
                />
                <SidebarRoute
                  exact
                  path={entities.mission.urls().list}
                  permission="missions:find"
                  component={Missions}
                />
                <SidebarRoute
                  path={entities.mission.urls().create}
                  permission="missions:create"
                  component={MissionDetailPage}
                  mode={DetailModeEnum.CREATE}
                />
                <SidebarRoute
                  path={entities.mission.urls().clone(ID_PARAM)}
                  permission="missions:create"
                  component={MissionDetailPage}
                  mode={DetailModeEnum.CLONE}
                />
                <SidebarRoute
                  path={entities.mission.urls().bulkEdit}
                  permission="missions:patch"
                  component={MissionBulkEditPage}
                  mode={DetailModeEnum.EDIT}
                />
                <SidebarRoute
                  path={entities.mission.urls().detail(ID_PARAM)}
                  permission="missions:get"
                  component={MissionDetailPage}
                  mode={DetailModeEnum.EDIT}
                />
                <SidebarRoute
                  exact
                  path={entities.event.urls().list}
                  permission="events:find"
                  component={Events}
                />
                <SidebarRoute
                  path={entities.event.urls().create}
                  permission="events:create"
                  component={EventDetailPage}
                  mode={DetailModeEnum.CREATE}
                />
                <SidebarRoute
                  path={entities.event.urls().clone(ID_PARAM)}
                  permission="events:create"
                  component={EventDetailPage}
                  mode={DetailModeEnum.CLONE}
                />
                <SidebarRoute
                  path={entities.event.urls().detail(ID_PARAM)}
                  permission="events:get"
                  component={EventDetailPage}
                  mode={DetailModeEnum.EDIT}
                />
                <SidebarRoute
                  path={entities.sample.urls().list}
                  exact
                  permission="samples:find"
                  component={Samples}
                />
                <SidebarRoute
                  path={entities.sample.urls().detail(ID_PARAM)}
                  permission="samples:get"
                  component={SampleDetailPage}
                  mode={DetailModeEnum.EDIT}
                />
                <SidebarRoute
                  exact
                  path={entities.tdp.urls().list}
                  permission="tdps:find"
                  component={TdpsPage}
                  clientOptions={['enableTdp']}
                />
                <SidebarRoute
                  path={entities.tdp.urls().detail(ID_PARAM)}
                  permission="tdps:get"
                  component={TdpsDetailPage}
                  mode={DetailModeEnum.EDIT}
                  clientOptions={['enableTdp']}
                />
                <SidebarRoute
                  path={entities.tdp.urls().create}
                  permission="tdps:create"
                  component={TdpsDetailPage}
                  mode={DetailModeEnum.CREATE}
                  clientOptions={['enableTdp']}
                />
                <SidebarRoute
                  path={entities.tdp.urls().clone(ID_PARAM)}
                  permission="tdps:create"
                  component={TdpsDetailPage}
                  mode={DetailModeEnum.CLONE}
                  clientOptions={['enableTdp']}
                />
                <SidebarRoute
                  exact
                  path={entities.test.urls().list}
                  permission="tests:find"
                  component={Tests}
                />
                <SidebarRoute
                  path={entities.test.urls().create}
                  permission="tests:create"
                  component={TestDetailPage}
                  mode={DetailModeEnum.CREATE}
                />
                <SidebarRoute
                  path={entities.test.urls().bulkEdit()}
                  permission="tests:patch"
                  component={TestBulkEditPage}
                  mode={DetailModeEnum.EDIT}
                />
                <SidebarRoute
                  path={entities.test.urls().individualBulk()}
                  permission="tests:patch"
                  component={TestIndividualBulkEditPage}
                  clientOptions={['enableIndividualBulkEdit']}
                />
                <SidebarRoute
                  path={entities.test.urls().detail(ID_PARAM)}
                  permission="tests:get"
                  component={TestDetailPage}
                  mode={DetailModeEnum.EDIT}
                />
                <SidebarRoute
                  path={entities.test.urls().clone(ID_PARAM)}
                  permission="tests:create"
                  component={TestDetailPage}
                  mode={DetailModeEnum.CLONE}
                />
                <SidebarRoute
                  component={TestResultDetailPage}
                  permission="testResults:create"
                  path={entities.testResult.urls(TEST_ID_PARAM).create}
                  mode={DetailModeEnum.CREATE}
                />
                <SidebarRoute
                  component={TestResultDetailPage}
                  permission="testResults:patch"
                  path={entities.testResult.urls(TEST_ID_PARAM).detail(ID_PARAM)}
                  mode={DetailModeEnum.EDIT}
                />
                <SidebarRoute
                  component={TestReturnsPage}
                  permission="testReturns:find"
                  path={entities.testReturn.urls().list}
                  exact
                  clientOptions={['enableTestReturns']}
                />
                <SidebarRoute
                  path={entities.testReturn.urls().detail(ID_PARAM)}
                  permission="testReturns:get"
                  component={TestReturnDetailPage}
                  mode={DetailModeEnum.EDIT}
                  clientOptions={['enableTestReturns']}
                />
                <SidebarRoute
                  path={entities.testReturn.urls().clone(ID_PARAM)}
                  permission="testReturns:create"
                  component={TestReturnDetailPage}
                  mode={DetailModeEnum.CLONE}
                  clientOptions={['enableTestReturns']}
                />
                <SidebarRoute
                  path={entities.user.urls().detail(ID_PARAM)}
                  permission="users:patch"
                  component={UserEditPage}
                  mode={DetailModeEnum.EDIT}
                />
                <SidebarRoute
                  permission="users:find"
                  component={Users}
                  path={entities.user.urls().list}
                  exact
                  restrictedToRoles={[RolesId.SUPER_ADMINISTRATOR, RolesId.ADMINISTRATOR]}
                />
                <SidebarRoute
                  path={entities.settings.urls().controlSettings()}
                  permission="settings:find"
                  scopePermission="control"
                  component={ControlSettings}
                />
                <SidebarRoute
                  path={entities.settings.urls().outbound}
                  permission="settings:find"
                  scopePermission="outbound"
                  component={CommunicationSettings}
                />
                <SidebarRoute
                  path={entities.settings.urls().adams}
                  permission="settings:find"
                  scopePermission="ADAMS"
                  component={ADAMSSettings}
                  exact
                />
                <SidebarRoute
                  path={entities.settings.urls().appContent}
                  permission="settings:find"
                  scopePermission="contents"
                  component={ApplicationsContent}
                  exact
                />
                <SidebarRoute
                  permission="settings:find"
                  scopePermission="client"
                  component={CurrentClient}
                  path="/client"
                />
                <SidebarRoute
                  exact
                  path={entities.analysis.urls().list}
                  permission="analyses:find"
                  component={AnalysesPage}
                />
                <SidebarRoute
                  path={entities.analysis.urls().detail(ID_PARAM)}
                  permission="analyses:get"
                  component={AnalysesDetailPage}
                  mode={DetailModeEnum.EDIT}
                />
                <SidebarRoute
                  path={entities.authority.urls().list}
                  exact
                  permission="authorities:find"
                  component={Authority}
                />
                <SidebarRoute
                  path={entities.authority.urls().detail(ID_PARAM)}
                  permission="authorities:get"
                  component={AuthorityDetailPage}
                  mode={DetailModeEnum.EDIT}
                />
                <SidebarRoute
                  path={entities.authority.urls().create}
                  permission="authorities:create"
                  component={AuthorityDetailPage}
                  mode={DetailModeEnum.CREATE}
                />
                <SidebarRoute
                  path={entities.authority.urls().clone(ID_PARAM)}
                  permission="authorities:create"
                  component={AuthorityDetailPage}
                  mode={DetailModeEnum.CLONE}
                />
                <SidebarRoute
                  path={entities.federation.urls().list}
                  exact
                  permission="federations:find"
                  component={Federation}
                />
                <SidebarRoute
                  path={entities.federation.urls().detail(ID_PARAM)}
                  permission="federations:get"
                  component={FederationDetailPage}
                  mode={DetailModeEnum.EDIT}
                />
                <SidebarRoute
                  path={entities.federation.urls().create}
                  permission="federations:create"
                  component={FederationDetailPage}
                  mode={DetailModeEnum.CREATE}
                />
                <SidebarRoute
                  path={entities.federation.urls().clone(ID_PARAM)}
                  permission="federations:create"
                  component={FederationDetailPage}
                  mode={DetailModeEnum.CLONE}
                />
                <SidebarRoute
                  path={entities.fileType.urls().list}
                  exact
                  permission="fileTypes:find"
                  clientOptions={['enableAttachments']}
                  component={FileType}
                />
                <SidebarRoute
                  path={entities.fileType.urls().detail(ID_PARAM)}
                  permission="fileTypes:get"
                  clientOptions={['enableAttachments']}
                  component={FileTypeDetailPage}
                  mode={DetailModeEnum.EDIT}
                />
                <SidebarRoute
                  path={entities.documentType.urls().list}
                  exact
                  permission="documentTypes:find"
                  component={DocumentType}
                />
                <SidebarRoute
                  path={entities.documentType.urls().detail(ID_PARAM)}
                  permission="documentTypes:get"
                  component={DocumentTypeDetailPage}
                  mode={DetailModeEnum.EDIT}
                />
                <SidebarRoute
                  path={entities.availabilityCategory.urls().list}
                  exact
                  permission="availabilityCategories:find"
                  clientOptions={['enableAvailabilities']}
                  component={AvailabilityCategory}
                />
                <SidebarRoute
                  path={entities.availabilityCategory.urls().detail(ID_PARAM)}
                  permission="availabilityCategories:get"
                  clientOptions={['enableAvailabilities']}
                  component={AvailabilityCategoryDetailPage}
                  mode={DetailModeEnum.EDIT}
                />
                <SidebarRoute
                  path={entities.invoicingCode.urls().list}
                  exact
                  permission="invoicingCodes:find"
                  clientOptions={['enableDcoInvoices']}
                  component={InvoicingCodes}
                />
                <SidebarRoute
                  path={entities.invoicingCode.urls().detail(ID_PARAM)}
                  permission="invoicingCodes:get"
                  clientOptions={['enableDcoInvoices']}
                  component={InvoicingCodeDetailPage}
                  mode={DetailModeEnum.EDIT}
                />
                <SidebarRoute
                  path={entities.invoicingCode.urls().create}
                  permission="invoicingCodes:create"
                  component={InvoicingCodeDetailPage}
                  mode={DetailModeEnum.CREATE}
                />
                2
                <SidebarRoute
                  path={entities.invoicingCode.urls().clone(ID_PARAM)}
                  permission="invoicingCodes:create"
                  component={InvoicingCodeDetailPage}
                  mode={DetailModeEnum.CLONE}
                />
                <SidebarRoute
                  path={entities.country.urls().list}
                  exact
                  permission="countries:find"
                  component={Country}
                />
                <SidebarRoute
                  path={entities.country.urls().detail(ID_PARAM)}
                  permission="countries:get"
                  component={CountryDetailPage}
                  mode={DetailModeEnum.EDIT}
                />
                <SidebarRoute
                  path={entities.language.urls().list}
                  exact
                  permission="languages:find"
                  component={Language}
                />
                <SidebarRoute
                  path={entities.language.urls().detail(ID_PARAM)}
                  permission="languages:get"
                  component={LanguageDetailPage}
                  mode={DetailModeEnum.EDIT}
                />
                <SidebarRoute
                  path={entities.locale.urls().list}
                  exact
                  permission="locales:find"
                  component={Locale}
                />
                <SidebarRoute
                  path={entities.locale.urls().detail(ID_PARAM)}
                  permission="locales:get"
                  component={LocaleDetailPage}
                  mode={DetailModeEnum.EDIT}
                />
                <SidebarRoute
                  path={entities.paymentMethod.urls().list}
                  exact
                  permission="paymentMethods:find"
                  component={PaymentMethod}
                  clientOptions={['enableDcoInvoices']}
                />
                <SidebarRoute
                  path={entities.paymentMethod.urls().detail(ID_PARAM)}
                  permission="paymentMethods:get"
                  component={PaymentMethodDetailPage}
                  clientOptions={['enableDcoInvoices']}
                  mode={DetailModeEnum.EDIT}
                />
                <SidebarRoute
                  path={entities.courier.urls().list}
                  exact
                  permission="couriers:find"
                  component={Courier}
                />
                <SidebarRoute
                  path={entities.courier.urls().detail(ID_PARAM)}
                  permission="couriers:get"
                  component={CourierDetailPage}
                  mode={DetailModeEnum.EDIT}
                />
                <SidebarRoute
                  path={entities.courier.urls().create}
                  permission="couriers:create"
                  component={CourierDetailPage}
                  mode={DetailModeEnum.CREATE}
                />
                <SidebarRoute
                  path={entities.courier.urls().clone(ID_PARAM)}
                  permission="couriers:create"
                  component={CourierDetailPage}
                  mode={DetailModeEnum.CLONE}
                />
                <SidebarRoute
                  path={entities.label.urls().list}
                  exact
                  permission="labels:find"
                  clientOptions={['enableLabels']}
                  component={Label}
                  restrictedToRoles={[
                    RolesId.SUPER_ADMINISTRATOR,
                    RolesId.ADMINISTRATOR,
                    RolesId.TEST_PLANNER,
                    RolesId.VIEWER,
                  ]}
                />
                <SidebarRoute
                  path={entities.label.urls().detail(ID_PARAM)}
                  permission="labels:get"
                  component={LabelDetailPage}
                  mode={DetailModeEnum.EDIT}
                  clientOptions={['enableLabels']}
                  restrictedToRoles={[
                    RolesId.SUPER_ADMINISTRATOR,
                    RolesId.ADMINISTRATOR,
                    RolesId.TEST_PLANNER,
                    RolesId.VIEWER,
                  ]}
                />
                <SidebarRoute
                  path={entities.label.urls().create}
                  permission="labels:create"
                  component={LabelDetailPage}
                  mode={DetailModeEnum.CREATE}
                  clientOptions={['enableLabels']}
                  restrictedToRoles={[
                    RolesId.SUPER_ADMINISTRATOR,
                    RolesId.ADMINISTRATOR,
                    RolesId.TEST_PLANNER,
                    RolesId.VIEWER,
                  ]}
                />
                <SidebarRoute
                  path={entities.label.urls().clone(ID_PARAM)}
                  permission="labels:create"
                  component={LabelDetailPage}
                  mode={DetailModeEnum.CLONE}
                  clientOptions={['enableLabels']}
                  restrictedToRoles={[
                    RolesId.SUPER_ADMINISTRATOR,
                    RolesId.ADMINISTRATOR,
                    RolesId.TEST_PLANNER,
                    RolesId.VIEWER,
                  ]}
                />
                <SidebarRoute
                  path={entities.lab.urls().list}
                  exact
                  permission="labs:find"
                  component={Laboratory}
                />
                <SidebarRoute
                  path={entities.lab.urls().detail(ID_PARAM)}
                  permission="labs:get"
                  component={LaboratoryDetailPage}
                  mode={DetailModeEnum.EDIT}
                />
                <SidebarRoute
                  path={entities.lab.urls().create}
                  permission="labs:create"
                  component={LaboratoryDetailPage}
                  mode={DetailModeEnum.CREATE}
                />
                <SidebarRoute
                  path={entities.lab.urls().clone(ID_PARAM)}
                  permission="labs:create"
                  component={LaboratoryDetailPage}
                  mode={DetailModeEnum.CLONE}
                />
                <SidebarRoute
                  path={entities.sampleManufacturer.urls().list}
                  exact
                  permission="sampleManufacturers:find"
                  component={SampleManufacturerPage}
                />
                <SidebarRoute
                  path={entities.sampleManufacturer.urls().detail(ID_PARAM)}
                  permission="sampleManufacturers:get"
                  component={SampleManufacturerDetailPage}
                  mode={DetailModeEnum.EDIT}
                />
                <SidebarRoute
                  path={entities.sampleManufacturer.urls().create}
                  permission="sampleManufacturers:create"
                  component={SampleManufacturerDetailPage}
                  mode={DetailModeEnum.CREATE}
                />
                <SidebarRoute
                  path={entities.sampleManufacturer.urls().clone(ID_PARAM)}
                  permission="sampleManufacturers:create"
                  component={SampleManufacturerDetailPage}
                  mode={DetailModeEnum.CLONE}
                />
                <SidebarRoute
                  path={entities.tdpClass.urls().list}
                  exact
                  permission="tdpClasses:find"
                  component={TdpClassesPage}
                  clientOptions={['enableTdp']}
                />
                <SidebarRoute
                  path={entities.tdpClass.urls().detail(ID_PARAM)}
                  permission="tdpClasses:get"
                  component={TdpClassesDetailPage}
                  mode={DetailModeEnum.EDIT}
                  clientOptions={['enableTdp']}
                />
                <SidebarRoute
                  path={entities.tdpClass.urls().create}
                  permission="tdpClasses:create"
                  component={TdpClassesDetailPage}
                  mode={DetailModeEnum.CREATE}
                  clientOptions={['enableTdp']}
                />
                <SidebarRoute
                  path={entities.tdpClass.urls().clone(ID_PARAM)}
                  permission="tdpClasses:create"
                  component={TdpClassesDetailPage}
                  mode={DetailModeEnum.CLONE}
                  clientOptions={['enableTdp']}
                />
                <SidebarRoute
                  path={entities.sportDiscipline.urls().list}
                  exact
                  permission="sportDisciplines:find"
                  component={SportDiscipline}
                />
                <SidebarRoute
                  path={entities.sportDiscipline.urls().detail(ID_PARAM)}
                  permission="sportDisciplines:get"
                  component={SportDisciplineDetailPage}
                  mode={DetailModeEnum.EDIT}
                />
                <SidebarRoute
                  path={entities.sportDiscipline.urls().create}
                  permission="sportDisciplines:create"
                  component={SportDisciplineDetailPage}
                  mode={DetailModeEnum.CREATE}
                />
                <SidebarRoute
                  path={entities.sportDiscipline.urls().clone(ID_PARAM)}
                  permission="sportDisciplines:create"
                  component={SportDisciplineDetailPage}
                  mode={DetailModeEnum.CLONE}
                />
                <SidebarRoute
                  path={entities.pool.urls().list}
                  exact
                  permission="pools:find"
                  component={Pool}
                />
                <SidebarRoute
                  path={entities.pool.urls().detail(ID_PARAM)}
                  permission="pools:get"
                  component={PoolDetailPage}
                  mode={DetailModeEnum.EDIT}
                />
                <SidebarRoute
                  path={entities.pool.urls().create}
                  permission="pools:create"
                  component={PoolDetailPage}
                  mode={DetailModeEnum.CREATE}
                />
                <SidebarRoute
                  path={entities.pool.urls().clone(ID_PARAM)}
                  permission="pools:create"
                  component={PoolDetailPage}
                  mode={DetailModeEnum.CLONE}
                />
                <SidebarRoute
                  path={entities.sport.urls().list}
                  exact
                  permission="sports:find"
                  component={Sport}
                />
                <SidebarRoute
                  path={entities.sport.urls().detail(ID_PARAM)}
                  permission="sports:get"
                  component={SportDetailPage}
                  mode={DetailModeEnum.EDIT}
                />
                <SidebarRoute
                  path={entities.sport.urls().create}
                  permission="sports:create"
                  component={SportDetailPage}
                  mode={DetailModeEnum.CREATE}
                />
                <SidebarRoute
                  path={entities.sport.urls().clone(ID_PARAM)}
                  permission="sports:create"
                  component={SportDetailPage}
                  mode={DetailModeEnum.CLONE}
                />
                <SidebarRoute
                  component={UAInternalReasonsDetailPage}
                  path={entities.testUaInternalReason.urls().create}
                  permission="testUaInternalReasons:create"
                  mode={DetailModeEnum.CREATE}
                />
                <SidebarRoute
                  component={UAInternalReasonsDetailPage}
                  path={entities.testUaInternalReason.urls().detail(ID_PARAM)}
                  permission="testUaInternalReasons:get"
                  mode={DetailModeEnum.EDIT}
                />
                <SidebarRoute
                  component={UAInternalReasonsDetailPage}
                  path={entities.testUaInternalReason.urls().clone(ID_PARAM)}
                  permission="testUaInternalReasons:create"
                  mode={DetailModeEnum.CLONE}
                />
                <SidebarRoute
                  component={UAInternalReasonsPage}
                  path={entities.testUaInternalReason.urls().list}
                  permission="testUaInternalReasons:find"
                />
                <SidebarRoute
                  component={TestCancellationReasonsDetailPage}
                  path={entities.testCancellationReason.urls().create}
                  permission="testCancellationReasons:create"
                  restrictedToRoles={[
                    RolesId.SUPER_ADMINISTRATOR,
                    RolesId.ADMINISTRATOR,
                    RolesId.TEST_PLANNER,
                  ]}
                  mode={DetailModeEnum.CREATE}
                />
                <SidebarRoute
                  component={TestCancellationReasonsDetailPage}
                  path={entities.testCancellationReason.urls().detail(ID_PARAM)}
                  permission="testCancellationReasons:get"
                  restrictedToRoles={[
                    RolesId.SUPER_ADMINISTRATOR,
                    RolesId.ADMINISTRATOR,
                    RolesId.TEST_PLANNER,
                    RolesId.VIEWER,
                  ]}
                  mode={DetailModeEnum.EDIT}
                />
                <SidebarRoute
                  component={TestCancellationReasonsDetailPage}
                  path={entities.testCancellationReason.urls().clone(ID_PARAM)}
                  permission="testCancellationReasons:create"
                  restrictedToRoles={[
                    RolesId.SUPER_ADMINISTRATOR,
                    RolesId.ADMINISTRATOR,
                    RolesId.TEST_PLANNER,
                  ]}
                  mode={DetailModeEnum.CLONE}
                />
                <SidebarRoute
                  component={TestReturnReasonsDetailPage}
                  path={entities.testReturnReason.urls().create}
                  permission="testReturnReasons:create"
                  clientOptions={['enableTestReturns']}
                  restrictedToRoles={[
                    RolesId.SUPER_ADMINISTRATOR,
                    RolesId.ADMINISTRATOR,
                    RolesId.TEST_PLANNER,
                  ]}
                  mode={DetailModeEnum.CREATE}
                />
                <SidebarRoute
                  component={TestReturnReasonsDetailPage}
                  path={entities.testReturnReason.urls().detail(ID_PARAM)}
                  permission="testReturnReasons:get"
                  clientOptions={['enableTestReturns']}
                  restrictedToRoles={[
                    RolesId.SUPER_ADMINISTRATOR,
                    RolesId.ADMINISTRATOR,
                    RolesId.TEST_PLANNER,
                    RolesId.VIEWER,
                  ]}
                  mode={DetailModeEnum.EDIT}
                />
                <SidebarRoute
                  component={TestReturnReasonsDetailPage}
                  path={entities.testReturnReason.urls().clone(ID_PARAM)}
                  permission="testReturnReasons:create"
                  clientOptions={['enableTestReturns']}
                  restrictedToRoles={[
                    RolesId.SUPER_ADMINISTRATOR,
                    RolesId.ADMINISTRATOR,
                    RolesId.TEST_PLANNER,
                  ]}
                  mode={DetailModeEnum.CLONE}
                />
                <SidebarRoute
                  component={TestCancellationReasonsPage}
                  path={entities.testCancellationReason.urls().list}
                  permission="testCancellationReasons:find"
                  restrictedToRoles={[
                    RolesId.SUPER_ADMINISTRATOR,
                    RolesId.ADMINISTRATOR,
                    RolesId.TEST_PLANNER,
                    RolesId.VIEWER,
                  ]}
                />
                <SidebarRoute
                  component={TestReturnReasonsPage}
                  path={entities.testReturnReason.urls().list}
                  permission="testReturnReasons:find"
                  clientOptions={['enableTestReturns']}
                  restrictedToRoles={[
                    RolesId.SUPER_ADMINISTRATOR,
                    RolesId.ADMINISTRATOR,
                    RolesId.TEST_PLANNER,
                    RolesId.VIEWER,
                  ]}
                />
                <SidebarRoute
                  component={TestReturnResolutionsDetailPage}
                  path={entities.testReturnResolution.urls().create}
                  permission="testReturnResolutions:create"
                  clientOptions={['enableTestReturns']}
                  mode={DetailModeEnum.CREATE}
                />
                <SidebarRoute
                  component={TestReturnResolutionsDetailPage}
                  path={entities.testReturnResolution.urls().detail(ID_PARAM)}
                  permission="testReturnResolutions:get"
                  clientOptions={['enableTestReturns']}
                  mode={DetailModeEnum.EDIT}
                />
                <SidebarRoute
                  component={TestReturnResolutionsDetailPage}
                  path={entities.testReturnResolution.urls().clone(ID_PARAM)}
                  permission="testReturnResolutions:create"
                  clientOptions={['enableTestReturns']}
                  mode={DetailModeEnum.CLONE}
                />
                <SidebarRoute
                  component={TestReturnResolutionsPage}
                  path={entities.testReturnResolution.urls().list}
                  permission="testReturnResolutions:find"
                  clientOptions={['enableTestReturns']}
                />
                <SidebarRoute
                  component={DcasPage}
                  permission="dcas:find"
                  path={entities.dca.urls().list}
                  clientOptions={['enableDcas']}
                  exact
                />
                <SidebarRoute
                  path={entities.dca.urls().detail(ID_PARAM)}
                  permission="dcas:get"
                  component={DcasDetailPage}
                  mode={DetailModeEnum.EDIT}
                  clientOptions={['enableDcas']}
                />
                <SidebarRoute
                  path={entities.dca.urls().create}
                  permission="dcas:create"
                  component={DcasDetailPage}
                  mode={DetailModeEnum.CREATE}
                  clientOptions={['enableDcas']}
                />
                <SidebarRoute
                  path={entities.dca.urls().clone(ID_PARAM)}
                  permission="dcas:create"
                  component={DcasDetailPage}
                  mode={DetailModeEnum.CLONE}
                  clientOptions={['enableDcas']}
                />
                <SidebarRoute
                  component={LocationsPage}
                  permission="locations:find"
                  path={entities.location.urls().list}
                  exact
                />
                <SidebarRoute
                  path={entities.location.urls().detail(ID_PARAM)}
                  permission="locations:get"
                  component={LocationsDetailPage}
                  mode={DetailModeEnum.EDIT}
                />
                <SidebarRoute
                  path={entities.location.urls().create}
                  permission="locations:create"
                  component={LocationsDetailPage}
                  mode={DetailModeEnum.CREATE}
                />
                <SidebarRoute
                  path={entities.location.urls().clone(ID_PARAM)}
                  permission="locations:create"
                  component={LocationsDetailPage}
                  mode={DetailModeEnum.CLONE}
                />
                <SidebarRoute
                  component={FilesPage}
                  permission="files:find"
                  path={entities.attachment.urls().list}
                  clientOptions={['enableAttachments']}
                  exact
                />
                <SidebarRoute
                  component={PermissionsPage}
                  permission="admin/permissions:find"
                  path={entities.permissions.urls().list}
                  exact
                />
                <SidebarRoute
                  component={ReportsPage}
                  permission="reports:create"
                  path={entities.report.urls().list}
                  exact
                />
                <SidebarRoute
                  component={ClientStatisticsPage}
                  permission="reports:create"
                  path="/client-statistics"
                  exact
                />
                <SidebarRoute
                  exact
                  path={entities.agenda.urls().list}
                  permission="profileAvailabilities:find"
                  clientOptions={['enableAvailabilities']}
                  component={AgendaPage}
                />
                <SidebarRoute
                  clientOptions={['enableOrders']}
                  component={OrdersPage}
                  permission="orders:find"
                  path={entities.order.urls().list}
                  exact
                />
                <SidebarRoute
                  path={entities.order.urls().detail(ID_PARAM)}
                  permission="orders:get"
                  component={OrdersDetailPage}
                  mode={DetailModeEnum.EDIT}
                  clientOptions={['enableOrders']}
                />
                <SidebarRoute
                  path={entities.order.urls().create}
                  permission="orders:create"
                  component={OrdersDetailPage}
                  mode={DetailModeEnum.CREATE}
                  clientOptions={['enableOrders']}
                />
                <SidebarRoute
                  path={entities.order.urls().clone(ID_PARAM)}
                  permission="orders:create"
                  component={OrdersDetailPage}
                  mode={DetailModeEnum.CLONE}
                  clientOptions={['enableOrders']}
                />
                <SidebarRoute
                  path={entities.orderItem.urls(ORDER_ID_PARAM).bulkEdit()}
                  permission="orders:patch"
                  component={OrderItemBulkEditPage}
                  mode={DetailModeEnum.EDIT}
                  clientOptions={['enableOrders']}
                />
                <SidebarRoute
                  path={entities.orderItem.urls(ORDER_ID_PARAM).detail(ID_PARAM)}
                  permission="orderItems:get"
                  component={OrderItemDetailPage}
                  mode={DetailModeEnum.EDIT}
                  clientOptions={['enableOrders']}
                />
                <SidebarRoute
                  path={entities.orderItem.urls(ORDER_ID_PARAM).create}
                  permission="orderItems:create"
                  component={OrderItemDetailPage}
                  mode={DetailModeEnum.CREATE}
                  clientOptions={['enableOrders']}
                />
                <SidebarRoute
                  path={entities.orderItem.urls(ORDER_ID_PARAM).clone(ID_PARAM)}
                  permission="orderItems:create"
                  component={OrderItemDetailPage}
                  mode={DetailModeEnum.CLONE}
                  clientOptions={['enableOrders']}
                />
                <SidebarRoute
                  component={CostsPage}
                  permission="costs:find"
                  path={entities.cost.urls().list}
                  exact
                  clientOptions={['enableCosts']}
                />
                <SidebarRoute
                  path={entities.cost.urls().detail(ID_PARAM)}
                  permission="costs:patch"
                  component={CostsDetailPage}
                  mode={DetailModeEnum.EDIT}
                  clientOptions={['enableCosts']}
                />
                <SidebarRoute
                  path={entities.cost.urls().create}
                  permission="costs:create"
                  component={CostsDetailPage}
                  mode={DetailModeEnum.CREATE}
                  clientOptions={['enableCosts']}
                />
                <SidebarRoute
                  path={entities.cost.urls().clone(ID_PARAM)}
                  permission="costs:create"
                  component={CostsDetailPage}
                  mode={DetailModeEnum.CLONE}
                  clientOptions={['enableCosts']}
                />
                <SidebarRoute
                  component={PaymentPlansPage}
                  permission="paymentPlans:find"
                  path={entities.paymentPlan.urls().list}
                  exact
                  clientOptions={['enableDcoInvoices']}
                />
                <SidebarRoute
                  component={PaymentPlansDetailPage}
                  permission="paymentPlans:create"
                  path={entities.paymentPlan.urls().create}
                  mode={DetailModeEnum.CREATE}
                  clientOptions={['enableDcoInvoices']}
                />
                <SidebarRoute
                  component={PaymentPlansDetailPage}
                  permission="paymentPlans:create"
                  path={entities.paymentPlan.urls().clone(ID_PARAM)}
                  mode={DetailModeEnum.CLONE}
                  clientOptions={['enableDcoInvoices']}
                />
                <SidebarRoute
                  component={PaymentPlansDetailPage}
                  permission="paymentPlans:find"
                  path={entities.paymentPlan.urls().detail(ID_PARAM)}
                  mode={DetailModeEnum.EDIT}
                  clientOptions={['enableDcoInvoices']}
                />
                <SidebarRoute
                  path={entities.dcoInvoice.urls(USER_ID_PARAM).create}
                  permission="dcoInvoices:create"
                  mode={DetailModeEnum.CREATE}
                  clientOptions={['enableDcoInvoices']}
                  component={DCOInvoiceDetailPage}
                />
                <SidebarRoute
                  path={entities.dcoInvoice.urls(USER_ID_PARAM).detail(ID_PARAM)}
                  permission="dcoInvoices:get"
                  mode={DetailModeEnum.EDIT}
                  clientOptions={['enableDcoInvoices']}
                  component={DCOInvoiceDetailPage}
                />
                <SidebarRoute
                  path={entities.dcoInvoice.urls(USER_ID_PARAM).clone(ID_PARAM)}
                  permission="dcoInvoices:get"
                  mode={DetailModeEnum.CLONE}
                  clientOptions={['enableDcoInvoices']}
                  component={DCOInvoiceDetailPage}
                />
                <SidebarRoute
                  path={entities.dcoInvoice.urls(USER_ID_PARAM).list}
                  permission="dcoInvoices:find"
                  mode={DetailModeEnum.CREATE}
                  clientOptions={['enableDcoInvoices']}
                  component={DCOInvoicesPage}
                />
                <SidebarRoute
                  exact
                  path={entities.auditLog.urls().list}
                  permission="auditLogs:find"
                  component={AuditLogsPage}
                />
                <SidebarRoute
                  path={entities.auditLog.urls().detail(ID_PARAM)}
                  permission="auditLogs:get"
                  component={AuditLogsDetailPage}
                />
                <SidebarRoute
                  path={entities.translation.urls(':project').detail(':language')}
                  permission={`${entities.translation.permission}:patch`}
                  component={TranslationsDetailPage}
                  mode={DetailModeEnum.EDIT}
                />
                <SidebarRoute
                  path={entities.translation.urls(':project').list}
                  permission={`${entities.translation.permission}:find`}
                  component={TranslationsListPage}
                />
                <SidebarRoute
                  path={entities.disability.urls().list}
                  exact
                  permission="disabilities:find"
                  component={Disability}
                />
                <SidebarRoute
                  path={entities.disability.urls().detail(ID_PARAM)}
                  permission="disabilities:get"
                  component={DisabilityDetailPage}
                  mode={DetailModeEnum.EDIT}
                />
                {detailShortcutEntity && entities[detailShortcutEntity] && (
                  <Redirect
                    to={entities[detailShortcutEntity].urls('').detail(detailShortcutId!)}
                  />
                )}
                <FullscreenRoute
                  component={() => (
                    <ErrorPage
                      title={t('The page you are looking for wasn’t found or doesn’t exist.')}
                    />
                  )}
                />
              </Switch>
            </AppRedirectGuard>
          </ConnectedRouter>
        </PersistGate>
      </HelmetProvider>
    </Provider>
  );
};

export default App;

/**
 * Watch for initial query params,
 * so if page was triggered with redirect_uri
 * and we have logged user
 * we can redirect to app using oauth flow
 */
const AppRedirectGuard: FC<PropsWithChildren> = ({ children }) => {
  const user = useAppSelector((s) => s.core.user);
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const isAppRedirect = `${localStorage.getItem('initialUrl')}`.indexOf('redirect_uri') > -1;

  useEffect(() => {
    (async () => {
      if (user && isAppRedirect && pathname !== '/login') {
        dispatch(logout());
      }
    })();
  }, [dispatch, pathname, isAppRedirect, user]);

  return user && isAppRedirect ? null : <>{children}</>;
};
