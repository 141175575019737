import { FC } from 'react';

import { RolesId } from 'app/models/Role';
import MaterialIcon from 'core/components/MaterialIcon';

import { entities } from './entity';
import { urls } from './urls';

export interface NavSubItem {
  key: string;
  text: string;
  to: string;
  notifications?: number;
  /** optional parameter - if its set, we are looking for one of the specified client options */
  clientOption?: string[];
  /**
   * Optional custom permission - if not set key is used instead
   */
  permission?: string;
  /**
   * Optional roles that will be the only one with this item
   */
  restrictedToRoles?: RolesId[];
  search?: boolean;
}

export interface NavGroup {
  key: string;
  title: string;
  img: FC;
  items: NavSubItem[];
  to?: string;
  /** optional parameter - if its set, we are looking for one of the specified client options */
  clientOption?: string[];
  /**
   * Optional custom permission - if not set ${key}:find is used instead
   */
  permission?: string;
  /**
   * Optional roles that will be the only one with this item
   */
  restrictedToRoles?: RolesId[];
  /** Optional parameter - if entity supports searching */
  search?: boolean;
}

const RESTRICTED_LIST_ENTITY = [
  RolesId.SUPER_ADMINISTRATOR,
  RolesId.ADMINISTRATOR,
  RolesId.DCC,
  RolesId.API,
  RolesId.TEST_PLANNER,
  RolesId.VIEWER,
];

// TODO: improve the way we just add entity name from entities and "to", "search" properties would be taken from entities by entity key
// => https://cannypack.atlassian.net/browse/MODFE-1974
const getNavGroups = (t: (translateText: string) => string): NavGroup[] => [
  {
    key: 'dashboard',
    title: t('Dashboard'),
    img: () => <MaterialIcon icon="table_chart" />,
    permission: 'dashboard:view',
    items: [],
    to: urls.dashboard,
  },
  {
    key: 'control_planning',
    title: t('Control Planning'),
    img: () => <MaterialIcon icon="content_paste" />,
    items: [
      {
        key: 'orders',
        text: t('Orders'),
        to: entities.order.urls().list,
        clientOption: ['enableOrders'],
        search: true,
      },
      { key: 'athletes', text: t('Athletes'), to: entities.athlete.urls().list, search: true },
      {
        key: 'agenda',
        text: t('Agenda'),
        to: entities.agenda.urls().list,
        clientOption: ['enableAvailabilities'],
        restrictedToRoles: [
          RolesId.SUPER_ADMINISTRATOR,
          RolesId.ADMINISTRATOR,
          RolesId.TEST_PLANNER,
          RolesId.VIEWER,
        ],
        search: true,
      },
      {
        key: 'tdps',
        text: t('Distribution Plans'),
        to: entities.tdp.urls().list,
        clientOption: ['enableTdp'],
        search: true,
      },
      {
        key: 'events',
        text: t('Events'),
        to: entities.event.urls().list,
        search: true,
      },
    ],
  },

  {
    key: 'control_execution',
    title: t('Control Execution'),
    img: () => <MaterialIcon icon="content" />,
    items: [
      {
        key: 'missions',
        text: t('Missions'),
        to: entities.mission.urls().list,
        search: true,
      },
      { key: 'tests', text: t('Tests'), to: entities.test.urls().list, search: true },

      {
        key: 'testReturns',
        text: t('Test Returns'),
        to: entities.testReturn.urls().list,
        clientOption: ['enableTestReturns'],
        search: true,
      },
      {
        key: 'testAaReports',
        text: t('After Action Reports'),
        to: entities.testAaReport.urls().list,
        clientOption: ['enableAaReport'],
        search: true,
      },
      {
        key: 'testDcoReports',
        text: t('Control Reports'),
        to: entities.testDcoReport.urls(':testsId').list,
        clientOption: ['enableDcr', 'enableDcoReport'],
        search: true,
      },
      {
        text: t('UA Reports'),
        key: 'testUaReports',
        to: entities.testUaReport.urls(':testsId').list,
        search: true,
      },
    ],
  },

  {
    key: 'result_management',
    title: t('Result Management'),
    img: () => <MaterialIcon icon="content_check" />,
    items: [
      { key: 'cocs', text: t('Chain of Custody'), to: entities.coc.urls().list, search: true },
      {
        key: 'samples',
        text: t('Samples'),
        to: entities.sample.urls().list,
        search: true,

        restrictedToRoles: [
          RolesId.SUPER_ADMINISTRATOR,
          RolesId.ADMINISTRATOR,
          RolesId.TEST_PLANNER,
          RolesId.VIEWER,
        ],
      },
      {
        key: 'reports',
        text: t('Reports'),
        to: entities.report.urls().list,
        restrictedToRoles: [RolesId.SUPER_ADMINISTRATOR, RolesId.ADMINISTRATOR, RolesId.VIEWER],
      },
    ],
  },

  {
    key: 'finance',
    title: t('Finance'),
    img: () => <MaterialIcon icon="attach_money" />,
    items: [
      {
        key: 'costs',
        text: t('Costs'),
        to: entities.cost.urls().list,
        clientOption: ['enableCosts'],
        search: true,
      },
      {
        key: 'dcoInvoices',
        text: t('Invoices'),
        to: entities.dcoInvoice.urls(':usersId').list,
        clientOption: ['enableDcoInvoices'],
        search: true,
      },
    ],
  },

  {
    key: 'settings:find',
    title: t('Administration'),
    img: () => <MaterialIcon icon="settings" />,
    items: [
      {
        key: 'users',
        text: t('Users'),
        to: entities.user.urls().list,
        restrictedToRoles: [RolesId.SUPER_ADMINISTRATOR, RolesId.ADMINISTRATOR],
        search: true,
      },
      {
        key: 'client',
        text: t('Client Profile'),
        to: entities.client.urls().current,
      },
      {
        key: 'control',
        text: t('Control Settings'),
        to: urls.controlSettings,
      },
      {
        key: 'application',
        text: t('Application Contents'),
        to: urls.applicationContents,
      },
      {
        key: 'outbound',
        text: t('Outbound Communication'),
        to: urls.communicationSettings,
      },
      {
        key: 'ADAMS',
        text: t('ADAMS Integration'),
        to: urls.adamsSettings,
      },
      {
        key: 'paymentPlans',
        text: t('Payment Plans'),
        to: entities.paymentPlan.urls().list,
        clientOption: ['enableDcoInvoices'],
        search: true,
      },
      {
        key: 'files',
        text: t('Attachments'),

        to: urls.attachment,
        clientOption: ['enableAttachments'],
      },
      {
        key: 'projectTranslations',
        text: t('Translations'),
        to: entities.translation.urls('').list,
        search: true,
      },
      {
        key: 'auditLogs',
        text: t('Audit Logs'),
        to: entities.auditLog.urls().list,
        search: true,
      },
    ],
  },
  {
    key: 'lists',
    title: t('Lists'),
    img: () => <MaterialIcon icon="list" />,
    items: [
      {
        key: 'analyses',
        text: t('Analyses'),
        to: entities.analysis.urls().list,
        restrictedToRoles: RESTRICTED_LIST_ENTITY,
        search: true,
      },
      {
        key: 'authorities',
        text: t('Authorities'),
        to: entities.authority.urls().list,
        search: true,
        restrictedToRoles: RESTRICTED_LIST_ENTITY,
      },
      {
        key: 'availabilityCategories',
        text: t('Availability Categories'),
        clientOption: ['enableAvailabilities'],
        to: entities.availabilityCategory.urls().list,
        search: true,
        restrictedToRoles: RESTRICTED_LIST_ENTITY,
      },
      {
        key: 'countries',
        text: t('Countries'),
        to: entities.country.urls().list,
        search: true,
        restrictedToRoles: RESTRICTED_LIST_ENTITY,
      },
      {
        key: 'couriers',
        text: t('Couriers'),
        to: entities.courier.urls().list,
        search: true,
        restrictedToRoles: RESTRICTED_LIST_ENTITY,
      },
      {
        key: 'dcas',
        text: t('DCAs'),
        permission: 'dcas:find',
        to: entities.dca.urls().list,
        clientOption: ['enableDcas'],
        search: true,
        restrictedToRoles: [
          RolesId.SUPER_ADMINISTRATOR,
          RolesId.ADMINISTRATOR,
          RolesId.TEST_PLANNER,
          RolesId.DCO,
        ],
      },
      {
        key: 'tdpClasses',
        text: t('Distribution Plan Classes'),
        to: entities.tdpClass.urls().list,
        clientOption: ['enableTdp'],
        restrictedToRoles: RESTRICTED_LIST_ENTITY,
      },
      {
        key: 'documentTypes',
        text: t('Document Types'),
        to: entities.documentType.urls().list,
        search: true,
        restrictedToRoles: RESTRICTED_LIST_ENTITY,
      },
      {
        key: 'federations',
        text: t('Federations'),
        to: entities.federation.urls().list,
        search: true,
        restrictedToRoles: RESTRICTED_LIST_ENTITY,
      },
      {
        key: 'fileTypes',
        text: t('File Types'),
        to: entities.fileType.urls().list,
        clientOption: ['enableAttachments'],
        search: true,
        restrictedToRoles: RESTRICTED_LIST_ENTITY,
      },
      {
        key: 'disabilities',
        text: t('Impairments'),
        to: entities.disability.urls().list,
        search: true,
        restrictedToRoles: RESTRICTED_LIST_ENTITY,
      },
      {
        key: 'invoicingCodes',
        text: t('Invoicing Codes'),
        to: entities.invoicingCode.urls().list,
        search: true,
        clientOption: ['enableDcoInvoices'],
        restrictedToRoles: RESTRICTED_LIST_ENTITY,
      },
      {
        key: 'labels',
        text: t('Labels'),
        to: entities.label.urls().list,
        search: true,
        clientOption: ['enableLabels'],
        restrictedToRoles: [
          RolesId.SUPER_ADMINISTRATOR,
          RolesId.ADMINISTRATOR,
          RolesId.TEST_PLANNER,
          RolesId.VIEWER,
        ],
      },
      {
        key: 'labs',
        text: t('Laboratories'),
        to: entities.lab.urls().list,
        search: true,
        restrictedToRoles: RESTRICTED_LIST_ENTITY,
      },
      {
        key: 'languages',
        text: t('Languages'),
        to: entities.language.urls().list,
        search: true,
        restrictedToRoles: RESTRICTED_LIST_ENTITY,
      },
      {
        key: 'locales',
        text: t('Locales'),
        to: entities.locale.urls().list,
        search: true,
        restrictedToRoles: RESTRICTED_LIST_ENTITY,
      },
      {
        key: 'locations',
        text: t('Locations'),
        to: entities.location.urls().list,
        restrictedToRoles: RESTRICTED_LIST_ENTITY,
        search: true,
      },
      {
        key: 'paymentMethods',
        text: t('Payment Methods'),
        to: entities.paymentMethod.urls().list,
        clientOption: ['enableDcoInvoices'],
        search: true,
        restrictedToRoles: RESTRICTED_LIST_ENTITY,
      },
      {
        key: 'pools',
        text: t('Pools'),
        to: entities.pool.urls().list,
        restrictedToRoles: RESTRICTED_LIST_ENTITY,
      },
      {
        key: 'sampleManufacturers',
        text: t('Sample Manufacturers'),
        to: entities.sampleManufacturer.urls().list,
        restrictedToRoles: RESTRICTED_LIST_ENTITY,
      },
      {
        key: 'sports',
        text: t('Sports'),
        to: entities.sport.urls().list,
        search: true,
        restrictedToRoles: RESTRICTED_LIST_ENTITY,
      },
      {
        key: 'sportDisciplines',
        text: t('Sport Disciplines'),
        to: entities.sportDiscipline.urls().list,
        search: true,
        restrictedToRoles: RESTRICTED_LIST_ENTITY,
      },
      {
        key: 'testCancellationReasons',
        text: t('Test Cancellation Reasons'),
        to: entities.testCancellationReason.urls().list,
        restrictedToRoles: [
          RolesId.SUPER_ADMINISTRATOR,
          RolesId.ADMINISTRATOR,
          RolesId.TEST_PLANNER,
          RolesId.VIEWER,
        ],
        search: true,
      },
      {
        key: 'testReturnReasons',
        text: t('Test Return Reasons'),
        to: entities.testReturnReason.urls().list,
        clientOption: ['enableTestReturns'],
        restrictedToRoles: [
          RolesId.SUPER_ADMINISTRATOR,
          RolesId.ADMINISTRATOR,
          RolesId.TEST_PLANNER,
          RolesId.VIEWER,
        ],
        search: true,
      },
      {
        key: 'testReturnResolutions',
        text: t('Test Return Resolutions'),
        to: entities.testReturnResolution.urls().list,
        clientOption: ['enableTestReturns'],
        search: true,
        restrictedToRoles: RESTRICTED_LIST_ENTITY,
      },
      {
        key: 'testUaInternalReasons',
        text: t('UA Internal Reasons'),
        to: entities.testUaInternalReason.urls().list,
        search: true,
        restrictedToRoles: RESTRICTED_LIST_ENTITY,
      },
    ],
  },
  {
    key: 'system',
    title: t('System'),
    img: () => <MaterialIcon icon="stars" />,
    restrictedToRoles: [RolesId.SUPER_ADMINISTRATOR],
    items: [
      { key: 'clients', text: t('Clients'), to: entities.client.urls().list, search: true },
      {
        key: 'reports',
        text: t('Client Statistics'),
        to: urls.clientStatistics,
      },
      {
        key: 'admin/permissions',
        text: t('Permissions'),
        to: urls.permissions,
      },
    ],
  },
];

export default getNavGroups;
